
import { computed, defineComponent } from 'vue'
import { useDailyReport } from '@/composable/useDailyReport'
import QTable from 'quasar/src/components/table/QTable.js';
import { DailyReportTraffic } from '@/types/daily-report-traffic'

export default defineComponent({
  name: 'DailyReportTrafficTable',

  setup() {
    const { data, isPrintMode } = useDailyReport()

    const colStyle = 'white-space: pre-wrap'
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'track',
        label: 'Bana',
        field: 'track',
        style: 'width: 100%;',
      },
      {
        align: 'left',
        name: 'canceled',
        label: 'Inställda tåg operativt',
        field: 'canceled',
        style:
          'min-width: 70px; width: 70px; max-width: 70px; white-space: pre-wrap',
      },
      {
        align: 'left',
        name: 'canceledPlanned',
        label: 'Inställda tåg planerat',
        field: 'regularity',
        style:
          'min-width: 70px; width: 70px; max-width: 70px; white-space: pre-wrap',
      },
      {
        align: 'left',
        name: 'decreased',
        label: 'Fordons avvikelser',
        field: 'decreased',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'crew',
        label: 'Bemannings avvikelser',
        field: 'crew',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'punctuality',
        label: 'Punktlighet',
        field: 'punctuality',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'regularity',
        label: 'Regularitet Operativt',
        field: 'regularity',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'regularityTotal',
        label: 'Regularitet Total',
        field: 'regularity',
        style: colStyle,
      },
    ]

    const total = computed(() => {
      return data.value.traffic.find((item) => item.track === 'Total')
    })

    const rows = computed(() => {
      return data.value.traffic.filter((item) => item.track !== 'Total')
    })

    function isTotalRow(row: DailyReportTraffic) {
      return (row.track || '').toLowerCase().includes('total')
    }

    return { rows, columns, total, isPrintMode, isTotalRow }
  },
})
