<template>
  <q-toolbar class="q-py-sm bg-white hideOnPrint">
    <h6 class="q-ma-none q-pa-sm">Dygnsrapport</h6>
    <q-space />
    <AppFieldDate
      :square="false"
      dense
      v-model="date"
      class="dailyReportDateInput q-mr-md"
      hide-bottom-space
    />
    <q-btn-dropdown
      label="Filter händelsetyp"
      class="q-mr-md"
      outline
      color="grey-7"
    >
      <template #label>
        <q-badge color="primary" floating>
          {{ filterEvenTypeCount }}
        </q-badge>
      </template>
      <q-list dense>
        <q-item
          tag="label"
          clickable
          v-for="eventType of eventTypes"
          :key="eventType"
        >
          <q-item-section avatar>
            <q-checkbox v-model="selectedFilterEventType" :val="eventType" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ eventType }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <q-btn-group outline>
      <q-btn
        outline
        color="grey-7"
        label="Skriv ut"
        icon-right="mdi-printer"
        @click="onPrint"
      />
    </q-btn-group>
  </q-toolbar>
  <q-separator />
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { useLupp } from '@/composable/useLupp'
import { isValid, format } from 'date-fns'
import { DailyReportTrack } from '@/types/daily-report-deviation'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { useEvent } from '@/composable/useEvent'
import { useProfile } from '@/composable/useProfile'
import { useFilterLocalStorage } from '@/composable/useFilterLocalStorage'

export default defineComponent({
  name: 'DailyReportToolBar',

  components: {
    AppFieldDate,
  },

  setup() {
    const { currentProject } = useProfile()
    const { fetchAll, fetchDailyReportEventSection } = useEvent()
    const {
      getAllTraffic,
      getAllVehicleStatus,
      getAllDeviation,
      getAllEvent,
      date,
      isPrintMode,
      data,
      selectedFilterEventType,
    } = useDailyReport(format(new Date(), 'yyyy-MM-dd'))
    const { getFilterLocalstorage, setFilterLocalstorage } =
      useFilterLocalStorage()

    const { getAllTracks } = useLupp()

    const { fetchSummary } = useWithdrawalDashboard()

    watch(
      () => selectedFilterEventType.value,
      (value) => {
        if (!currentProject.value) return
        setFilterLocalstorage(
          { project: currentProject.value.name, view: 'dailyReportInternalV1' },
          {
            selectedEventTypes: value,
          }
        )
      }
    )

    watch(
      date,
      (v) => {
        if (v.length !== 10 || !isValid(new Date(v))) return
        fetchAll({
          from: v,
          to: v,
        })
        fetchDailyReportEventSection({
          from: v,
          to: v,
        })
        getAllTraffic(v)
        getAllVehicleStatus(v)
        getAllDeviation(v).then((data) => {
          selectedFilterEventType.value = getUniqueDeviationTypes(data).filter(
            (x) => x !== 'Försening'
          )

          if (currentProject.value) {
            const filter = getFilterLocalstorage({
              project: currentProject.value.name,
              view: 'dailyReportInternalV1',
            })

            if (filter.selectedEventTypes !== null) {
              selectedFilterEventType.value = filter.selectedEventTypes
            }
          }
        })
        getAllEvent(v)
        getAllTracks()
        fetchSummary({
          from: v,
          to: v,
          time: '07:00',
        })
      },
      {
        immediate: true,
      }
    )

    function getUniqueDeviationTypes(deviations: DailyReportTrack[]) {
      return [
        ...new Set(deviations.flatMap((x) => x.deviations).map((x) => x.type)),
      ]
    }

    const eventTypes = computed(() => {
      return getUniqueDeviationTypes(data.value.deviation)
    })

    function onPrint() {
      isPrintMode.value = true
      document.body.classList.add('printMode')
      setTimeout(() => {
        window.print()
        setTimeout(() => {
          isPrintMode.value = false
          document.body.classList.remove('printMode')
        }, 100)
      }, 200)
    }

    const filterEvenTypeCount = computed(() => {
      return selectedFilterEventType.value.length
    })

    return {
      date,
      onPrint,
      selectedFilterEventType,
      eventTypes,
      filterEvenTypeCount,
    }
  },
})
</script>

<style>
.dailyReportDateInput .q-field__control {
  height: 36px !important;
}
</style>
