
import { computed, defineComponent } from 'vue'
import DailyReportToolBar from '@/components/daily-report/DailyReportToolBar.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import DailyReportTrafficTable from '@/components/daily-report/DailyReportTrafficTable.vue'
import DailyReportVehicleStatusTable from '@/components/daily-report/DailyReportVehicleStatusTable.vue'
import DailyReportDeviationTable from '@/components/daily-report/DailyReportDeviationTable.vue'
import DailyReportEventTable from '@/components/daily-report/DailyReportEventTable.vue'
import { useVehicle } from '@/composable/useVehicle'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { DailyReportDeviation } from '@/types/daily-report-deviation'
import { useEvent } from '@/composable/useEvent'

export default defineComponent({
  name: 'DailyReport',

  components: {
    DailyReportToolBar,
    DailyReportTrafficTable,
    DailyReportVehicleStatusTable,
    DailyReportDeviationTable,
    DailyReportEventTable,
  },

  setup() {
    const {
      loading: dailyReportLoadingState,
      data,
      date,
      isPrintMode,
      selectedFilterEventType,
    } = useDailyReport()
    const { loading: eventLoading } = useEvent()
    const { loading: loadingStateVehicle, fetchAll: fetchAllVehicle } =
      useVehicle()
    const {
      loading: withdrawalDashboardLoading,
      data: withdrawalDashboardData,
    } = useWithdrawalDashboard()

    fetchAllVehicle()

    const loading = computed(() => {
      return (
        eventLoading.value.getAll ||
        dailyReportLoadingState.value.traffic.getAll ||
        dailyReportLoadingState.value.vehicleStatus.getAll ||
        dailyReportLoadingState.value.deviation.getAll ||
        dailyReportLoadingState.value.event.getAll ||
        loadingStateVehicle.value.getAll ||
        withdrawalDashboardLoading.value.fetchSummary
      )
    })

    const trackHasComment = (trackName: string) => {
      return data.value.event.some(
        (x) =>
          x.luppTracks.some((y) => y.description === trackName) && x.description
      )
    }

    const filterDeviation = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          trackHasComment(deviation.track) ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const filterDeviation2 = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const filteredDeviations = computed(() =>
      data.value.deviation
        .filter((deviation) => {
          return (
            deviation.deviations.filter(filterDeviation).length ||
            trackHasComment(deviation.name)
          )
        })
        .map((x) => ({
          ...x,
          deviations: x.deviations.filter(filterDeviation2),
        }))
    )

    return {
      date,
      filteredDeviations,
      data,
      loading,
      isPrintMode,
      withdrawalDashboardData,
    }
  },
})
