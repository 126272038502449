<template>
  <div class="q-pa-md">
    <q-table
      hide-bottom
      dense
      title="Trafiken – Operativa händelser"
      square
      :rows-per-page-options="[0]"
      :rows="rows"
      :columns="columns"
      class="dailyReportTrafficTable"
      separator="cell"
      :bordered="isPrintMode"
      :flat="isPrintMode"
    >
      <template v-slot:header-cell="props">
        <q-th :props="props">
          <div :style="props.col.style">
            {{ props.col.label }}
          </div>
        </q-th>
      </template>
      <template v-slot:top-row>
        <q-tr class="text-weight-bold" style="height: 40px; text-align: left">
          <q-td style="text-align: left"> Totalt </q-td>
          <q-td>
            {{ total?.canceled }}
          </q-td>
          <q-td>
            {{ total?.canceledPlanned }}
          </q-td>
          <q-td>
            {{ total?.decreased }}
          </q-td>
          <q-td>
            {{ total?.crew }}
          </q-td>
          <q-td>
            {{ total?.punctuality }}
          </q-td>
          <q-td>
            {{ total?.regularity }}
          </q-td>
          <q-td>
            {{ total?.regularityTotal }}
          </q-td>
        </q-tr>
      </template>

      <template v-slot:body="props">
        <q-tr
          :props="props"
          :class="[isTotalRow(props.row) && $style.totalRow]"
        >
          <q-td key="track" :props="props" style="white-space: pre-wrap">
            {{ props.row?.track }}
          </q-td>
          <q-td key="canceled" :props="props">
            {{ props.row?.canceled }}
          </q-td>
          <q-td key="canceledPlanned" :props="props">
            {{ props.row?.canceledPlanned }}
          </q-td>
          <q-td key="decreased" :props="props">
            {{ props.row?.decreased }}
          </q-td>
          <q-td key="crew" :props="props">
            {{ props.row?.crew }}
          </q-td>
          <q-td key="punctuality" :props="props">
            {{ props.row?.punctuality }}
          </q-td>
          <q-td key="regularity" :props="props">
            {{ props.row?.regularity }}
          </q-td>
          <q-td key="regularityTotal" :props="props">
            {{ props.row?.regularityTotal }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { QTable } from 'quasar'
import { DailyReportTraffic } from '@/types/daily-report-traffic'

export default defineComponent({
  name: 'DailyReportTrafficTable',

  setup() {
    const { data, isPrintMode } = useDailyReport()

    const colStyle = 'white-space: pre-wrap'
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'track',
        label: 'Bana',
        field: 'track',
        style: 'width: 100%;',
      },
      {
        align: 'left',
        name: 'canceled',
        label: 'Inställda tåg operativt',
        field: 'canceled',
        style:
          'min-width: 70px; width: 70px; max-width: 70px; white-space: pre-wrap',
      },
      {
        align: 'left',
        name: 'canceledPlanned',
        label: 'Inställda tåg planerat',
        field: 'regularity',
        style:
          'min-width: 70px; width: 70px; max-width: 70px; white-space: pre-wrap',
      },
      {
        align: 'left',
        name: 'decreased',
        label: 'Fordons avvikelser',
        field: 'decreased',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'crew',
        label: 'Bemannings avvikelser',
        field: 'crew',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'punctuality',
        label: 'Punktlighet',
        field: 'punctuality',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'regularity',
        label: 'Regularitet Operativt',
        field: 'regularity',
        style: colStyle,
      },
      {
        align: 'left',
        name: 'regularityTotal',
        label: 'Regularitet Total',
        field: 'regularity',
        style: colStyle,
      },
    ]

    const total = computed(() => {
      return data.value.traffic.find((item) => item.track === 'Total')
    })

    const rows = computed(() => {
      return data.value.traffic.filter((item) => item.track !== 'Total')
    })

    function isTotalRow(row: DailyReportTraffic) {
      return (row.track || '').toLowerCase().includes('total')
    }

    return { rows, columns, total, isPrintMode, isTotalRow }
  },
})
</script>

<style lang="scss">
.dailyReportTrafficTable {
  .q-table__top {
    background-color: #00a540;
    color: white;
  }

  thead tr:first-child th {
    background-color: #00a540;
    color: white;
  }

  tbody tr:first-child td {
    background-color: rgba(#00a540, 0.2);
  }
}
</style>

<style lang="scss" module>
.totalRow {
  background-color: rgba(#00a540, 0.1);
  font-weight: 600;
}
</style>
